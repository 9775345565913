<template>
  <div style="width:100%">
    <div v-for="(item, index) in items.items_registros" :key="index">
        <listadoSfc v-if="item.subestado_id === 9" :colores="colores" :item="item" :session="session" @view_sfc="ver_sfc"></listadoSfc>
        <listadoCorreo v-else-if="item.subestado_id === 8" :colores="colores" :item="item" :session="session" @view_correo="ver_correo"></listadoCorreo>
        <listadoMicrositio v-else-if="item.subestado_id === 7" :colores="colores" :item="item" :session="session" @view_micrositio="ver_micrositio"></listadoMicrositio>
        <listadoCrm v-else-if="item.subestado_id !== 5" :colores="colores" :item="item" :session="session" @view_crm="ver_crm"></listadoCrm>
    </div>
    <crmDialogo :dialogo="dialogLectura" :session="session" :gestion_id="gestion_id" :token="token" :items="items" :colores="colores" @closed_lectura="cerrar_lectura"></crmDialogo>
    <micrositioDialogo :dialogo="dialogMicrositio" :session="session" :gestion_id="gestion_id" :token="tokenSitio" :items="items" :colores="colores" @closed_micrositio="cerrar_micrositio"></micrositioDialogo>
    <correoDialogo :dialogo="dialogCorreo" :session="session" :gestion_id="gestion_id" :token="tokenCorreo" :items="items" :colores="colores" @closed_correo="cerrar_correo"></correoDialogo>
    <sfcDialogo :dialogo="dialogSfc" :session="session" :gestion_id="gestion_id" :token="tokenSfc" :items="items" :colores="colores" @closed_sfc="cerrar_sfc"></sfcDialogo>
  </div>
</template>

<script>
// import crmDialogo from '@/components/crm/lectura_dialogoComponent.vue'
// import micrositioDialogo from '@/components/micrositio/dialogoComponent.vue'
// import correoDialogo from '@/components/correos/dialogoComponent.vue'
// import listadoMicrositio from '@/components/bandejas/listados_micrositioComponent.vue'
// import listadoCorreo from '@/components/bandejas/listados_correosComponent.vue'
// import listadoCrm from '@/components/bandejas/listados_crmComponent.vue'

export default {
  name: 'bandeja_pqrsComponent',
  components: {
    micrositioDialogo: () => import('@/components/micrositio/dialogoComponent.vue'),
    listadoMicrositio: () => import('@/components/bandejas/listados_micrositioComponent.vue'),
    correoDialogo: () => import('@/components/correos/dialogoComponent.vue'),
    listadoCorreo: () => import('@/components/bandejas/listados_correosComponent.vue'),
    listadoCrm: () => import('@/components/bandejas/listados_crmComponent.vue'),
    crmDialogo: () => import('@/components/crm/lectura_dialogoComponent.vue'),
    sfcDialogo: () => import('@/components/sfc/dialogoComponent.vue'),
    listadoSfc: () => import('@/components/bandejas/listados_sfcComponent.vue')
  },
  props: ['colores', 'items', 'session'],
  data: () => ({
    dialogSfc: false,
    dialogLectura: false,
    dialogMicrositio: false,
    dialogCorreo: false,
    tokenSitio: 0,
    tokenCorreo: 0,
    token: 0,
    tokenSfc: 0,
    gestion_id: 0
  }),
  methods: {
    nuevo_pqr () {
      this.$emit('new_inbound')
    },
    cerrar_lectura () {
      this.dialogLectura = false
    },
    cerrar_micrositio () {
      this.dialogMicrositio = false
    },
    cerrar_correo () {
      this.dialogCorreo = false
    },
    cerrar_sfc () {
      this.dialogSfc = false
    },
    ver_correo (pItem) {
      this.dialogCorreo = true
      this.gestion_id = pItem.id
      this.tokenCorreo = this.tokenCorreo + 1
    },
    ver_micrositio (pItem) {
      this.gestion_id = pItem.id
      this.tokenSitio = this.tokenSitio + 1
      this.dialogMicrositio = true
    },
    ver_crm (pItem) {
      this.gestion_id = pItem.id
      this.token = this.token + 1
      this.dialogLectura = true
    },
    ver_sfc (pItem) {
      this.gestion_id = pItem.id
      this.tokenSfc = this.tokenSfc + 1
      this.dialogSfc = true
    }
  }
}
</script>
